//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import msDynamics from '~/mixins/msDynamics'
import { privacyLinkId } from '~/utils/gcmsIds'
import { getFormById, resolveCountryValue } from '~/utils/msDynamics'
import getLink from '~/gql/getLink'

export default {
  mixins: [msDynamics],

  data() {
    return {
      link: null,
      loading: false,
      submitted: false,
      error: false,
      formData: {
        firstname: null,
        lastname: null,
        email: null,
        country: null,
        privacy: false,
        newsletter: true,
      },
      invalidFields: {
        firstname: null,
        lastname: null,
        email: null,
        country: null,
        privacy: false,
        newsletter: false,
      },
      listeners: {
        change: (event) => this.onInput('country', event.target.value),
      },
      options: {},
    }
  },

  async fetch() {
    // get link
    const { link } = await this.$graphcms.request(getLink, {
      id: privacyLinkId,
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })
    this.link = link ?? null

    // fetch countries
    await this.$store.dispatch('locale/fetchWorldCountries')

    // set select options
    if (this.only && this.only.length > 0) {
      const worldCountries = this.$store.getters['locale/getWorldCountries']
      this.options = worldCountries.filter((country) =>
        this.only.includes(country.isoAlpha3)
      )
      this.allowedValues = this.only
    } else {
      const { data, countryCodes } =
        this.$store.getters['locale/getCountriesSelectOptions']
      this.options = data
      this.allowedValues = countryCodes
    }
  },

  computed: {
    privacyHint() {
      if (!this.link) return null
      const privacyLink = `<a href="${this.link.url}" title="${this.link.title}" target="_blank">${this.link.title}</a>`
      const translation = this.$translation('form.privacyHint', { privacyLink })
      return translation
    },
  },

  methods: {
    buildMsd() {
      return {
        main: getFormById('main'),
        newsletter: getFormById('newsletter'),
      }
    },

    onInput(key, value) {
      this.formData = {
        ...this.formData,
        [key]: value,
      }
    },

    async submitMsd() {
      const results = await Promise.all([
        this.msDynamics.main.actions.submit(),
        this.msDynamics.newsletter.actions.submit(),
      ])
      return results[0]
    },

    async saveSubmitLogs() {},

    async onSubmit() {
      this.error = false
      this.loading = true
      this.submitted = false

      if (this.userBranch === '') {
        console.log('matomo disabled for local environments')
      } else {
        // Custom Step Matomo Tracking
        // eslint-disable-next-line no-undef
        _paq.push(['setCustomUrl', 'ContactHearingDiaryForm - Submit'])

        // Track the page view in Matomo
        // eslint-disable-next-line no-undef
        _paq.push(['trackPageView', 'ContactHearingDiaryForm - Submit'])
        // eslint-disable-next-line no-undef
        _paq.push(['trackEvent', 'HearingDiary Form'])
      }

      try {
        // sync values to msd forms form
        await this.syncMainForm()
        await this.syncNewsletterForm()

        // submit msd forms
        const success = await this.submitMsd()
        if (!success) {
          this.error = true
        }
      } catch (e) {
        console.error(e)
        this.error = true
      }

      try {
        // Track Conversions API
        await this.$fb.sendEvent({
          eventName: 'DownloadJournal',
          eventSourceUrl: window.location.href,
        })
      } catch (e) {
        console.error(e)
      }

      this.saveSubmitLogs().catch(console.error)

      this.loading = false
      this.submitted = true
    },

    async syncMainForm() {
      const fields = this.msDynamics.main.actions
      fields.firstName(this.formData.firstname || '')
      fields.lastName(this.formData.lastname || '')
      fields.email(this.formData.email || '')
      fields.contactConsent(this.formData.newsletter || false)
      await fields.country(
        resolveCountryValue(this.formData.country, this.$store)
      )
      fields.gender('unknown')
      fields.website(window.location.href)
      const textContent = []
      if (this.focusText) {
        textContent.push(['Submission Recipient', this.focusText])
      }

      fields.textField(textContent.map((v) => v.join('\n')).join('\n\n'))
    },
    async syncNewsletterForm() {
      const fields = this.msDynamics.newsletter.actions
      fields.mentoringProgram(true)
      fields.firstName(this.formData.firstname || '')
      fields.lastName(this.formData.lastname || '')
      fields.email(this.formData.email || '')
      await fields.country(
        resolveCountryValue(this.formData.country, this.$store)
      )
      fields.language(
        this.$store.getters['locale/getDefaultNameByLanguageCode'](
          this.$i18n.locale || 'english'
        )
      )
    },
  },
}
